<template>
  <Layout>
    <PageHeader :title="page.title" :items="page.items" />
    <b-form @submit="onSubmit" @reset="onReset">
      <b-card>
        <b-row>
          <b-col cols="10">
            <!-- 登录账号 -->
            <form-row>
              <b-form-group
                label="Account Email:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="account"
                label-class="requiredRow"
              >
                <b-form-input
                  id="account"
                  v-model="form.username"
                  placeholder="Please enter"
                  required
                  oninvalid="setCustomValidity('Please enter');"
                  maxlength="100"
                  :state="
                    form.username &&
                    form.username.length >= 6 &&
                    form.username.length <= 100
                  "
                  oninput="setCustomValidity('')"
                  :disabled="editType"
                ></b-form-input>
              </b-form-group>
              <template #right>
                <CountText :len="form.username.length" max="100" />
              </template>
            </form-row>

            <!-- 名称 -->
            <form-row>
              <b-form-group
                label="First Name:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="firstName"
                label-class="requiredRow"
              >
                <b-form-input
                  id="firstName"
                  v-model="form.firstName"
                  trim
                  placeholder="Please enter"
                  required
                  maxlength="60"
                  oninput="setCustomValidity('')"
                ></b-form-input>
              </b-form-group>

              <template #right>
                <CountText :len="form.firstName.length" max="60" />
              </template>
            </form-row>
            <!-- 姓氏 -->
            <form-row>
              <b-form-group
                label="Last Name:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="lastName"
                label-class="requiredRow"
              >
                <b-form-input
                  id="lastName"
                  v-model="form.lastName"
                  trim
                  placeholder="Please enter"
                  required
                  maxlength="60"
                  oninput="setCustomValidity('')"
                ></b-form-input>
              </b-form-group>
              <template #right>
                <CountText :len="form.lastName.length" max="60" />
              </template>
            </form-row>

            <!-- 选择角色 -->
            <form-row>
              <div class="form-group">
                <b-form-group
                  label="Role:"
                  label-cols-lg="3"
                  label-align-lg="right"
                  label-for="role"
                  label-class="requiredRow"
                >
                  <b-form-select
                    id="role"
                    v-model="form.roleId"
                    :options="types.roleList"
                    value-field="id"
                    text-field="roleName"
                    required
                    oninvalid="setCustomValidity('Please select');"
                    oninput="setCustomValidity('');"
                  >
                  </b-form-select>
                </b-form-group>
              </div>
            </form-row>

            <!-- 密码 -->
            <form-row>
              <b-form-group
                label="Password:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="password"
                label-class="requiredRow"
              >
                <b-form-input
                  id="password"
                  v-model="form.password"
                  trim
                  required
                  placeholder="Please enter"
                  type="password"
                  :disabled="editType"
                  :state="
                    form.password &&
                    form.password.length >= 6 &&
                    form.password.length <= 60
                  "
                  maxlength="60"
                  oninvalid="setCustomValidity('Please enter');"
                  oninput="setCustomValidity('');"
                ></b-form-input>
                <b-button
                  v-if="editType"
                  variant="outline-secondary"
                  @click="modalShow = !modalShow"
                  >Reset Passwords</b-button
                >
              </b-form-group>
              <template #right v-if="!editType">
                <CountText :len="form.password.length" max="60" />
              </template>
            </form-row>

            <!-- 确认密码 -->
            <form-row v-if="!editType">
              <b-form-group
                label="Confirm password:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="password"
                label-class="requiredRow"
              >
                <b-form-input
                  id="password"
                  v-model="confirmPassword"
                  trim
                  required
                  placeholder="Please enter"
                  type="password"
                  :state="
                    confirmPassword &&
                    confirmPassword.length >= 6 &&
                    confirmPassword.length <= 60 &&
                    form.password == confirmPassword
                  "
                  maxlength="60"
                  oninvalid="setCustomValidity('Please enter');"
                  oninput="setCustomValidity('')"
                ></b-form-input>
              </b-form-group>


              <template #right>
                <CountText :len="confirmPassword.length" max="60" />
              </template>
            </form-row>

            <!-- 头像 -->
            <form-row>
              <b-form-group
                label="Profile Photo:"
                label-cols-lg="3"
                label-align-lg="right"
                label-for="Photo"
              >
                <input
                  type="file"
                  class="filestyle"
                  data-input="false"
                  id="filestyle-1"
                  style="position: absolute; clip: rect(0px, 0px, 0px, 0px)"
                  tabindex="-1"
                  @change="upload($event)"
                />

                <div class="bootstrap-filestyle input-group">
                  <div
                    name="filedrag"
                    style="
                      position: absolute;
                      width: 100%;
                      height: 35px;
                      z-index: -1;
                    "
                  ></div>
                  <span class="group-span-filestyle" tabindex="0">
                    <div v-if="form.picture" style="margin-bottom: 10px">
                      <img
                        :src="form.picture"
                        style="
                          width: 100px;
                          height: 100px;
                          border: 2px dashed #ccc;
                        "
                      />
                    </div>
                    <label
                      for="filestyle-1"
                      style="margin-bottom: 0"
                      class="btn btn-info"
                    >
                      <span class="buttonText"> upload picture </span>
                    </label>
                    <span>
                      Recommended size of picture: 120 pixels * 120 pixels,
                      supporting JPG and PNG formats.
                    </span>
                  </span>
                </div>
              </b-form-group>
            </form-row>
            <!-- 修改密码弹框   no-close-on-esc
              no-close-on-backdrop -->
            <b-modal
              ref="modal"
              v-model="modalShow"
              title="Edit Password"
              @hidden="resetModal"
              hide-header
              hide-footer
              centered
            >
              <form ref="form" @submit="onSure" @reset="onClose">
                <b-form-group
                  label="Password:"
                  label-cols-lg="4"
                  label-align-lg="right"
                  label-for="password"
                  label-class="requiredRow"
                >
                  <b-form-input
                    id="password"
                    v-model="modalData.password"
                    trim
                    required
                    placeholder="Enter password"
                    type="password"
                    :state="
                      modalData.password &&
                      modalData.password.length >= 6 &&
                      modalData.password.length <= 60
                    "
                    oninvalid="setCustomValidity('Please enter');"
                    oninput="setCustomValidity('');"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Confirm password:"
                  label-cols-lg="4"
                  label-align-lg="right"
                  label-for="password"
                  label-class="requiredRow"
                >
                  <b-form-input
                    id="password"
                    v-model="modalData.confirmPassword"
                    trim
                    required
                    placeholder="Enter password"
                    type="password"
                    :state="
                      modalData.confirmPassword &&
                      modalData.confirmPassword.length >= 6 &&
                      modalData.confirmPassword.length <= 60 &&
                      modalData.password == modalData.confirmPassword
                    "
                    oninvalid="setCustomValidity('Please enter');"
                    oninput="setCustomValidity('');"
                  ></b-form-input>
                </b-form-group>
                <b-form-group label-cols-lg="4">
                  <b-button type="submit" variant="primary" class="custom-width"
                    >Save</b-button
                  >
                  <b-button type="reset" class="ml-4 custom-width"
                    >Cancel</b-button
                  >
                </b-form-group>
              </form>
            </b-modal>
          </b-col>
        </b-row>
      </b-card>
      <b-form-group label-cols-lg="3">
        <b-button type="submit" class="custom-width" variant="primary"
          >Save</b-button
        >
        <b-button type="reset" class="ml-4 custom-width">Cancel</b-button>
      </b-form-group>
    </b-form>
    <Dialog
      v-model="successAlear"
      :imageSrc="require('@/assets/success.png')"
      noBtn="OK"
      @cancel="cancel"
      @hide="hide"
    >
      Saved successfully
    </Dialog>
    <!-- 错误提示框 -->
    <Dialog
      v-model="errorAlear"
      :imageSrc="require('@/assets/confirm.svg')"
      noBtn="OK"
      @cancel="cancel1"
      @hide="hide1"
    >
      {{ errorContent }}
    </Dialog>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";

import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Multiselect from "vue-multiselect";

import { onReady, upload_img } from "@/api/upload";
import FormRow from "@/components/FormRow.vue";
import Dialog from "@/components/Dialog";
import CountText from "@/components/CountText.vue";
/**
 * FAQ Add component
 */
export default {
  components: {
    Layout,
    PageHeader,
    ckeditor: CKEditor.component,
    Multiselect,
    FormRow,
    Dialog,
    CountText,
  },
  data() {
    return {
      page: {
        title: this.editId ? "Modify Account" : "Create Account",
        items: [
          {
            text: this.$store.getters.getTitle,
            // href: "/"
          },
          {
            text: "System Settings",
            // href: "/"
          },
          {
            text: "OMNI Account Mgmt",
            // href: "/"
          },
          {
            text: this.editId ? "Modify Account" : "Create Account",
            active: true,
          },
        ],
      },
      form: {
        username: "",
        nickName: "",
        roleId: "",
        password: "",
        firstName: "",
        lastName: "",
        phone: "",
        picture: "",
        status: 0,
      },
      types: {
        roleList: [{ id: "", roleName: "All Role" }],
        status: [
          { text: "Enabled", value: 0, disabled: false },
          { text: "Disabled", value: 1, disabled: false },
        ],
      },
      modalShow: false,
      modalData: {
        password: "",
        confirmPassword: "",
      },
      editor: ClassicEditor,
      editId: window.location.search,
      editType: false,
      photoName: "",
      confirmPassword: "",
      successAlear: false,
      errorAlear: false,
      errorContent: "",
    };
  },
  beforeCreate() {
    this.editId = window.location.search;
  },
  created() {
    if (this.editId) {
      this.editId = this.editId.split("=")[1];
      this.editType = true;
      this.formData(this.editId);
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    onReady: onReady,
    init() {
      this.$api.Dropdown.selectAllSubAccountRole({
        id: this.editId,
      }).then((res) => {
        if (res.success) {
          this.types.roleList = this.types.roleList.concat(res.data);
        }
      });
    },
    onSubmit(evt) {
      evt.preventDefault();
      if (this.editId) {
        this.$api.systemManage.updateById(this.form).then((res) => {
          if (res.success) {
            this.successAlear = true;
          } else {
            if (res.message) {
              this.errorAlear = true;
              this.errorContent = res.message;
            }
          }
        });
      } else {
        if (this.form.password != this.confirmPassword) {
          this.errorAlear = true;
          this.errorContent = "The two passwords are inconsistent.";
        } else {
          this.$api.systemManage.add(this.form).then((res) => {
            if (res.success) {
              this.successAlear = true;
            } else {
              if (res.message) {
                this.errorAlear = true;
                this.errorContent = res.message;
              }
            }
          });
        }
      }
    },
    onReset(evt) {
      evt.preventDefault();
      this.form = {
        username: "",
        nickName: "",
        roleId: "",
        password: "",
        firstName: "",
        phone: "",
        picture: "",
        status: 0,
      };
      this.$nextTick(() => {
        // this.show = true;
        this.$router.go(-1);
      });
    },
    upload(e) {
      let file = e.target.files[0];
      this.photoName = file.name;
      if (/image/.test(file.type)) {
        upload_img(file).then((res) => {
          if (res.message) {
            this.$toast(res);
          }
          if (res.success) {
            this.form.picture = res.data;
            console.log("zzzzzzzz", this.form);
          }
        });
      } else {
        e.target.files = [];
        this.$bvToast.toast("only support jpg/png file");
      }
    },
    formData(id) {
      this.$api.systemManage.findById({ id: id }).then((res) => {
        if (res.success) {
          this.form = res.data;
          this.form.password = "123456";
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      });
    },
    onSure(evt) {
      evt.preventDefault();
      if (this.modalData.password == this.modalData.confirmPassword) {
        this.$api.systemManage
          .changePasswordById({
            id: this.editId,
            password: this.modalData.password,
          })
          .then((res) => {
            if (res.success) {
              this.modalShow = !this.modalShow;
              this.successAlear = true;
            } else {
              if (res.message) {
                this.$toast(res);
              }
            }
          });
      }
    },
    onClose(evt) {
      evt.preventDefault();
      this.modalData = {
        password: "",
        confirmPassword: "",
      };
      this.modalShow = !this.modalShow;
    },
    resetModal() {
      this.modalData = {
        password: "",
        confirmPassword: "",
      };
    },
    cancel() {
      this.$router.go(-1);
    },
    hide() {
      this.$router.go(-1);
    },
    cancel1() {
      this.errorAlear = false;
    },
    hide1() {
      this.errorAlear = false;
    },
  },
};
</script>
